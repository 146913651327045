<template>
  <div>
    <div v-if="value" class="y-pointer" style="position: relative;width: 3rem;height:3rem">
      <div style="position: absolute;right: 0;top: 0;z-index: 1">
        <div @click="value=''" class="flex-def flex-zCenter flex-cCenter" style="padding: .2rem;background-color: red;color: #FFFFFF">
          <i class="el-icon-delete-solid"></i>
        </div>
      </div>
      <el-image @click="dialogVisible=true"  :src="value | tomedia" style="width: 3rem;height:3rem"></el-image>
    </div>
    <div @click="dialogVisible=true" v-else class="y-pointer image-slot flex-def flex-zTopBottom" style="width: 3rem;height: 3rem">
      <i class="el-icon-picture-outline"></i>
      <div class="y-desc">添加图片</div>
    </div>
    <el-dialog custom-class="custom-dialog" append-to-body title="选择图片" :show-close="false" :visible.sync="dialogVisible" width="60rem" @open="open" :before-close="handleClose">
      <img_body ref="imgBody" @change="chooseImg"></img_body>
    </el-dialog>
  </div>
</template>

<script>

import img_body from "@/components/y_upload/img_body";
export default {
  name: "y_upload_img_box",
  components:{img_body},
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    full:{
      type:Boolean,
      default: false
    },
    modelval: {
      type:String,
      default:""
    }
  },
  watch:{
    modelval(){
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data() {
    return {
      dialogVisible: false,
      value: this.modelval,
    }
  },
  mounted() {

  },
  methods:{
    open(){
      // this.$refs.imgBody.open();
    },
    chooseImg(img){
      this.dialogVisible=false;
      if(this.full){
        this.value = this.$store.state.sys.global.attachment_url + img.attachment
        return;
      }
      this.value = img.attachment;
    },
    handleClose(done){
      done();
    },
  }
}
</script>
<style scoped>

</style>
<style scoped>
.image-slot {
  width: 3rem;
  height: 3rem;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}
.custom-dialog > .el-dialog__body{
  padding: 0;
}
</style>